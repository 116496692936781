import { ReactNode } from "react";
import { Card } from "components";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import RssFeed from "./RssFeed";

interface RssFeedCardProps {
  label: ReactNode;
}

export const RssFeedCard = ({ label }: RssFeedCardProps) => {
  const { t } = useModifiedTranslation();
  return (
    <Card header={label}>
      <div className="flex justify-between py-1 px-2 text-sm font-semibold text-gray-500 bg-gray-100">
        <div>{t("overviewPage.rssFeedTitle")}</div>
        <div>{t("overviewPage.rssFeedDate")}</div>
      </div>
      <div className="flex flex-col px-2 divide-y">
        <RssFeed
          label="Default"
          limit={8}
          loadingComponent={() => <div>Loading default..</div>}
          errorComponent={() => <div>Sadly, no default rss :(</div>}
        />
      </div>
    </Card>
  );
};
