import { FunctionComponent } from "react";
import rssEnhancer, { InjectionRSSProps } from "react-rss";
import { dateToStringYYYYMMDD } from "../../utils/date";

const RSS_FEED_URL = process.env.REACT_APP_RSS_FEED_URL;

const RssFeed: FunctionComponent<
  { label: string; limit: number } & InjectionRSSProps
> = (props) => {
  return (
    <div className="flex flex-col px-2 divide-y">
      {props.rss.items.slice(0, props.limit).map((item, key) => (
        <a key={key} href={item.link} target="_blank" rel="noreferrer">
          <div className="flex justify-between items-center py-2 cursor-pointer">
            <div className="w-3/4 text-sm font-semibold text-gray-900">
              {item.title}
            </div>
            <div className="w-1/4 text-sm font-light text-right whitespace-nowrap">
              {dateToStringYYYYMMDD(new Date(item.pubDate))}
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default rssEnhancer(RssFeed, RSS_FEED_URL);
