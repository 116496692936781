export const toShortISOString = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export const startOfMonth = (date: Date) => {
  const resultDate = new Date(date);
  resultDate.setDate(1);
  resultDate.setHours(0, 0, 0, 0);

  return resultDate;
};

export const dateFromYYYYMMDD = (stringDate: string) => {
  const dateArray = stringDate.split("-").map((period) => parseInt(period));
  return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
};

export const dateToYYYYMMDD = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const dateToStringYYYYMMDD = (date: Date) => {
  let day: number | string = date.getDate();
  day = day < 10 ? `0${day}` : day;

  let month: number | string = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;

  return `${date.getFullYear()}-${month}-${day}`;
};

export const isDateInRange = (
  date: Date,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const dateTime = date.getTime();
  const isOlder = !startDate || dateTime >= startDate.getTime();
  const isYounger = !endDate || dateTime <= endDate.getTime();

  return isOlder && isYounger;
};

export const timeSince = (date: Date) => {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " YEARS AGO";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " MONTHS AGO";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) > 1
      ? Math.floor(interval) + " DAYS AGO"
      : Math.floor(interval) + " DAY AGO";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " HOURS AGO";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " MINUTES AGO";
  }
  return Math.floor(seconds) + " SECONDS AGO";
};
